<template>
  <div>
    <Head :title="$t('genesisNode.title')"></Head>
    <div class="center" style="padding: 0 20px;" :style="'width:'+$setWidth">
      <div class="node_title">{{$t('genesisNode.title1')}}</div>

      <div class="node_box1 flex_row">
        <img src="@/image/yes.png" class="node_icon" alt="">
        <div class="node_text">{{$t('genesisNode.text1')}}V{{level}}</div>
        <img :src="require('@/image/vip/V' + level+'.png')" style="width:20px;height:20px;margin-left:10px;" alt="">
      </div>

      <div class="node_box1 flex_row">
        <img src="@/image/yes.png" v-if="upLevel[level]!=undefined&&upLevel[level].team<=info.powerTeam" class="node_icon" alt="">
        <img src="@/image/no.png" v-else class="node_icon" alt="">
        <div class="node_text">{{$t('genesisNode.text2')}}{{info.powerTeam}}</div>
      </div>

      <div class="node_box1 flex_lr" >
        <div class="flex_row">
          <img src="@/image/yes.png" v-if="pledge_arr[0]<=user.userPledgeMt" class="node_icon" alt="">
          <img src="@/image/no.png" v-else class="node_icon" alt="">
          <div class="node_text flex_row">质押MT {{user.userPledgeMt}}</div>
        </div>
         <div class="flex_row">
          <img src="@/image/yes.png" v-if="pledge_arr[1]<=user.userPledgeUlm" class="node_icon" alt="">
          <img src="@/image/no.png" v-else class="node_icon" alt="">
          <div class="node_text flex_row">质押ULME{{user.userPledgeUlm}}</div>
        </div>
      </div>
      <!-- <div class="node_box1">
        <div class="node_title2">{{$t('genesisNode.subtitle1')}}</div>
        <div class="node_box2">
          <div class="flex_lr">
            <div class="flex_row">
              <img src="@/image/yes.png" class="node_icon" alt="">
              <div class="node_text">{{$t('genesisNode.text3')}}450 （≈900.00USDT）</div>
            </div>
            <div class="node_btn hand">{{$t('genesisNode.btn1')}}</div>
          </div>
          <div class="flex_lr">
            <div class="flex_row">
              <img src="@/image/no.png" class="node_icon" alt="">
              <div class="node_text">{{$t('genesisNode.text4')}}50000.00ULME</div>
            </div>
            <div class="node_btn hand">{{$t('genesisNode.btn1')}}</div>
          </div>
        </div>
      </div>

      <div class="node_box1">
        <div class="node_title2">{{$t('genesisNode.subtitle2')}}</div>
        <div class="node_box2">
          <div class="flex_lr">
            <div class="flex_row">
              <img src="@/image/no.png" class="node_icon" alt="">
              <div class="node_text">{{$t('genesisNode.text5')}}1MTG</div>
            </div>
            <div class="node_btn hand">{{$t('genesisNode.btn1')}}</div>
          </div>
        </div>
      </div>

      <div class="node_box1">
        <div class="node_box2 flex_row">
          <img src="@/image/no.png" class="node_icon" alt="">
          <div class="node_text">{{$t('genesisNode.text6').replace(/\/MAX\//g,'10').replace(/\/PRESENT\//g,'5')}}</div>
        </div>
      </div>
      
      <div class="node_box1">
        <div class="node_box2 flex_row">
          <img src="@/image/no.png" class="node_icon" alt="">
          <div class="node_text">{{$t('genesisNode.text7').replace(/\/MAX\//g,'3').replace(/\/PRESENT\//g,'2')}}</div>
        </div>
      </div>
      
      <div class="node_box1">
        <div class="node_box2 flex_row">
          <img src="@/image/no.png" class="node_icon" alt="">
          <div class="node_text">{{$t('genesisNode.text8')}}100000.00</div>
        </div>
      </div> -->

      <div class="flex_lr">
        <div class="node_title">{{$t('genesisNode.title2')}}</div>
        <!-- <div class="node_subtitle flex_row" @click="toReceivingDetails">
          <div>{{$t('genesisNode.btn3')}}</div>
          <i class="ifont icone-qianjin" />
        </div> -->
      </div>
      <div class="node_box1">
        <div class="node_box2 flex_lr" style="margin:10px 0;">
          <div class="node_text">{{$t('genesisNode.text9')}}</div>
          <!-- <div class="node_btn2 hand">{{$t('genesisNode.btn2')}}</div> -->
        </div>
        <div class="node_box2 flex_lr" style="margin:10px 0;">
          <div class="node_text">{{$t('genesisNode.text10')}}</div>
          <!-- <div class="node_btn2 hand">{{$t('genesisNode.btn2')}}</div> -->
        </div>
        <div class="node_box2 flex_lr" style="margin:10px 0;">
          <div class="node_text">{{$t('genesisNode.text11')}}</div>
          <!-- <div class="node_btn2 hand">{{$t('genesisNode.btn2')}}</div> -->
        </div>
        <div class="node_box2 flex_lr" style="margin:10px 0;">
          <div class="node_text">{{$t('genesisNode.text12')}}</div>
          <!-- <div class="node_btn2 hand">{{$t('genesisNode.btn2')}}</div> -->
        </div>
        <div class="node_box2 flex_lr" style="margin:10px 0;">
          <div class="node_text">{{$t('genesisNode.text13')}}</div>
          <!-- <div class="node_btn2 hand">{{$t('genesisNode.btn2')}}</div> -->
        </div>
        <!-- <div class="node_box2 flex_lr" style="margin:10px 0;">
          <div class="node_text">当前可领{{info.mt}}MT  {{info.ulm}}ULME</div>
          <div class="node_btn2 hand" @click="take">{{$t('genesisNode.btn2')}}</div>
        </div> -->
        <div v-if="info.mt>0||info.ulm>0">
          <div class="node_btn3" v-if="(pledge_arr[0]>user.userPledgeMt||pledge_arr[1]>user.userPledgeUlm)" style="margin-top:10px;" 
        @click="toPage('/My_Farmer')">质押代币不足,请前去质押</div>
        <div v-else class="node_btn3 hand" @click="take">{{$t('genesisNode.btn2')}}  {{info.mt}}MT  {{info.ulm}}ULME</div>
        </div>
        

      <div v-if="timeout>=0">
        <div class="node_box2 flex_lr" style="margin:10px 0;">
          <div class="node_text">总释放额:{{info._balance}}</div>
        </div>
        <div class="node_box2 flex_lr" style="margin:10px 0;">
          <div class="node_text">已释放额:{{info._takeed}}</div>
        </div>
        <div class="node_box2 flex_lr" style="margin:10px 0;">
          <div class="node_text">释放时间:{{info.startTime}}</div>
        </div>

        <div class="node_btn3 hand" v-if="info.take_count_out>0" @click="take1">领取:{{info.take_count_out}}ULME</div>

      </div>

      </div>
    </div>
    <div style="height: 20px;"></div>
  </div>
</template>
 
<script>
import walletHelper from "@/utils/walletHelper.js"
import { Loading } from 'element-ui';
export default {
  data () {
    return {
      info:{},
      user:{},
      level:0,
      loading:0,
      timeout:-1,
      pledge_arr:[10,50000]
    }
  },
  computed: {
    upLevel(){
      return this.$store.state.user.levelconfig
    }
  },
  async mounted () {
    await this.$onLaunched;
    if(this.upLevel.length==0){
      walletHelper.getLevelconfig()
    }
    if(walletHelper.getAddress().toLowerCase()=='0x687D817F4C699BD02043f53b343979Ea43e273DB'.toLowerCase()){
      this.pledge_arr=[0,0]
    }

    this.init()
  },
  methods: {
        toPage(url){
      this.$router.push(url)
    },
        dd(){
        this.loading =Loading.service({
            lock: true,
            text: '交易正在处理中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    closeLoading(msg){
      if(msg&&msg!=''){
        this.$toast({
            title: msg
        });
      }
      this.loading&&this.loading.close();
    },
    init() {
      const that=this
      // this.get('', {}, 'POST').then(res => {
        
      // })
      try{
        walletHelper.getContract('cc67').methods.getCount(walletHelper.getAddress()).call().then(result=>{
            console.log(result)
            that.$set(that.info,'mt',walletHelper.Wei(result[0]))
            that.$set(that.info,'ulm',walletHelper.Wei(result[1]))
        })
        walletHelper.getContract('daoV1').methods.data(walletHelper.getAddress(),walletHelper.dataIndex._power).call().then(res=>{
        console.log(res)
        that.$set(that.info,'power',walletHelper.Wei(res))
      })
      walletHelper.getContract('daoV1h').methods.getTeamPower(walletHelper.getAddress()).call().then(res=>{
        console.log(res)
        that.$set(that.info,'powerTeam',res)
        
      })
      walletHelper.getUserData(walletHelper.getAddress(),(res,e)=>{
        if(res){
          that.user=res
                console.log(that.user.userPledgeMt)

            if(Number(res.power)>0||res._minerCount_390>0){
              walletHelper.getContract('daoV1h').methods.getTeamPower(walletHelper.getAddress()).call().then(res=>{
                console.log(res)
                that.$set(that.info,'powerTeam',res)

              })
            }
            that.level=res.userLevel
            walletHelper.getContract('daoV4').methods.getLevel(walletHelper.getContractAddress('rel'),walletHelper.getAddress()).call().then(level=>{
              if(that.level!=level){
                that.addPop2=true
              }
            })
          
        }else{
          console.log('getUserData错误  error',e,res)
        }
        
      })
      }catch(e){
         console.log(e)
      }
      this.getTimeOut()

    },
    getTimeOut(){
      const that=this
      for(let i=0;i<2;i++){
        walletHelper.getTimeOut(i).methods._account().call().then(add=>{
          console.log(add,walletHelper.getAddress())
          if(add.toLowerCase()==walletHelper.getAddress().toLowerCase()){
            that.timeout=i
            walletHelper.getTimeOut(i).methods.startTime().call().then(res=>{
              console.log(res)
              let date=new Date(Number(res)*1000)
              console.log(date.getTime(),res+'000')
              that.$set(that.info,'startTime',date.getDate()+"日"+date.getHours()+':'+date.getMinutes())
            })
            walletHelper.getTimeOut(i).methods._balance().call().then(res=>{
              that.$set(that.info,'_balance',walletHelper.Wei(res))
            })
            walletHelper.getTimeOut(i).methods._takeed().call().then(res=>{
              that.$set(that.info,'_takeed',walletHelper.Wei(res))
            })
            walletHelper.getTimeOut(i).methods._month().call().then(res=>{
              that.$set(that.info,'_month',res)
            })
            walletHelper.getTimeOut(i).methods.getCount().call().then(res=>{
              that.$set(that.info,'take_count_out',walletHelper.Wei(res))
            })
            

          }
        })
      }
      
    },
    take(){
      const that=this
      this.sendWeb3(walletHelper.getContract('cc67').methods.takeCount()).then(result=>{
          console.log(result)
          that.closeLoading('领取成功')
          that.init()
      })
    },
    take1(){
      const that=this
      if(that.timeout>=0&&that.info.take_count_out>0){
        this.sendWeb3(walletHelper.getTimeOut(that.timeout).methods.take()).then(result=>{
            console.log(result)
            that.closeLoading('领取成功')
            that.init()
        })
      }else{
        this.$toast('无法领取')
      }
      
    },
    // 领取详情
    toReceivingDetails(){
      this.$router.push('/Receiving_details')
    }
  }
}
</script>
 
<style lang="scss" scoped>
  .node_icon{
    width: 15px;
    margin-right: 10px;
  }
  .node_title{
    color: #00FFEA;
    font-size: 1.1rem;
    padding: 10px 0;
  }
  .node_subtitle{
    font-size: 0.95rem;
    font-family: Microsoft YaHei;
    color: #00FFEA;
    padding: 10px 0;
    i{
      margin-left: 5px;
      font-size: 0.95rem;
    }
  }
  .node_title2{
    color: #ffffff;
    font-size: 1rem;
    padding-bottom: 10px;
  }
  .node_text{
    color: #ffffff;
    font-size: 0.8rem;
    padding: 8px 0;
  }
  .node_box1{
    width: 100%;
    background: #0C2379;
    border-radius: 8px;
    padding: 10px 13px;
    margin: 15px 0;
  }
  .node_box2{
    width: 100%;
    background: #041453;
    border-radius: 8px;
    padding: 5px 13px;
  }
  .node_btn3{
    width: 100%;
    background: #00FFEA;
    border-radius: 8px;
    padding: 10px 13px;
    text-align: center;
  }
  .node_btn{
    text-align: center;
    border-radius: 5px;
    color: #05faeb;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 3px 18px;
    border: 1px solid #05faeb;
    white-space: nowrap;
  }
  .node_btn2{
    text-align: center;
    background: #113C78;
    border-radius: 5px;
    color: #ffffff;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 5px 18px;
    white-space: nowrap;
  }
</style>